<template>
  <div
    class="acts-list-wrapper col-md-12"
    v-if="diagnostics_list && diagnostics_list.length"
  >
    <table class="table table-sm text-center">
      <thead>
        <tr>
          <th
            :colspan="USER_INFO.status === 'admin' ? 13 : 12"
            class="text-center"
          >
            ремонты без зч и диагностики
          </th>
        </tr>
        <tr>
          <th>№</th>
          <th>№ СЦ</th>
          <th v-if="USER_INFO.status === 'admin'">СЦ</th>
          <th>создан</th>
          <th>инструмент</th>
          <th>группа</th>
          <th>S№</th>
          <th>дата продажи</th>
          <th>дата принятия в ремонт</th>
          <th>дефект</th>
          <th>продавец</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tr"
          v-for="(item, idx) in diagnostics_list"
          :key="idx"
          :class="item.no_read_messages > 0 ? 'message' : ''"
        >
          <td class="nowrap">
            {{ item.repair_number }}

            <div
              v-if="
                item.duplicate_serial_number && USER_INFO.status === 'admin'
              "
            >
              <a
                :href="
                  `https://sc-kalibr.ru/repairs/repair/${item.duplicate_serial_number}`
                "
                target="_blank"
                @click.prevent="view_duplicate(item)"
                >дубликат {{ item.duplicate_serial_number_count }}шт.</a
              >
            </div>
          </td>
          <td @click="goRepair(item.remont_id)">{{ item.receiptNumber }}</td>
          <td
            @click="goRepair(item.remont_id)"
            v-if="USER_INFO.status === 'admin'"
          >
            {{ item.user }}
          </td>
          <td @click="goRepair(item.remont_id)">{{ item.date_add }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.name_instr }}</td>
          <td @click="goRepair(item.remont_id)">
            {{ item.serialNumberGroup }}
          </td>
          <td @click="goRepair(item.remont_id)">{{ item.serialNumber }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.dateSale }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.dateRepair }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.defect }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.seller }}</td>
          <td @click="goRepair(item.remont_id)">
            <i class="bi bi-envelope-fill" v-if="item.no_read_messages > 0"
              ><a href="#" class="badge badge-danger"></a
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
    <DuplicateViews
      v-if="USER_INFO.status === 'admin'"
      :open="openDialog"
      :repairsId="duplicateId"
    />
  </div>
  <div v-else></div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { HTTPGET, HTTPGETNOLOADER } from "@/api";
import DuplicateViews from "@/components/DuplicateViews.vue";
export default {
  name: "DiagnosticsList",
  components: { DuplicateViews },
  computed: {
    ...mapGetters(["USER_INFO", "PATH"])
  },
  data: () => ({
    diagnostics_list: null,
    interval: null,
    openDialog: false,
    duplicateId: null
  }),
  mounted() {
    this.ADDPATH("ремонты без зч и диагностики");
    HTTPGET({
      url: "repairs/diagnostics_list.php"
    }).then(resp => (this.diagnostics_list = resp.data)),
      this.update_diagnostics_list();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["ADDPATH"]),
    goRepair(id) {
      this.$router.push(`/repairs/repair/${id}?path=${this.PATH}`);
    },
    update_diagnostics_list() {
      this.interval = setInterval(() => {
        HTTPGETNOLOADER({
          url: "repairs/diagnostics_list.php"
        }).then(resp => (this.diagnostics_list = resp.data));
      }, 5000);
    },
    view_duplicate(id) {
      this.duplicateId = id;
      this.openDialog = !this.openDialog;
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.7em;
  margin-top: 10px;
}
th {
  text-align: center;
  vertical-align: middle;
}
td {
  vertical-align: middle;
}
.tr:hover {
  cursor: pointer;
  background: rgb(247, 247, 247);
}
.acts-list-wrapper {
  overflow: auto;
}
i {
  font-size: 2em;
  color: red;
}
.message {
  color: red;
}
.nowrap {
  white-space: nowrap;
}
</style>
